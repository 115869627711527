import React from "react";

interface IProps {
  children: JSX.Element;
}
const Background = ({ children }: IProps) => {
  return <body className="bg-gray-200 dark:bg-gray-900 transition-all">{children}</body>;
};

export default Background;
