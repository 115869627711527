import { environment, envType } from "@redux/endpoint";
export const DisplayFormikState = (props: any) => {
  return environment !== envType.live ? (
    <>
     <details className="group">
          <summary className="flex items-center justify-between p-4 rounded-lg cursor-pointer bg-gray-200">
            <h5 className="font-medium text-gray-900">Show Form State</h5>

            <svg
              className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" stroke-linejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </summary>

          <div style={{ margin: "1rem 0" }} className="border border-2 border-primary rounded-md overflow-hidden">
            <h3 style={{ fontFamily: "monospace" }} />
            <pre
              style={{
                background: "#f6f8fa",
                fontSize: ".65rem",
                padding: ".5rem",
              }}
            >
              <strong>props</strong> = {JSON.stringify(props, null, 2)}
            </pre>
          </div>
        </details>
    </>
  
) : null;
}
