import { Modal } from "@components/layout";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import Button from "./Button";

import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { setShowConfirmationDialog } from "@redux/slices/globalSlice";

interface IProps {
  title?: string;
  show?: boolean;
  message?: string;
  no?: string;
  yes?: string;
  onConfirmation: () => void;
  onCancellation?: () => void;
  extraContent?: React.ReactElement
}

export default function ConfirmationDialog(props: IProps) {
  const dispatch = useAppDispatch();

  const { title, show, message, no, yes, onConfirmation,onCancellation,extraContent } = props;
  const { showConfirmDialog } = useAppSelector((state: any) => state.global);

  return (
    <>
      <Modal
        title={title ?? `Are you sure?`}
        show={show ?? showConfirmDialog}
        onClose={() => {
          dispatch(setShowConfirmationDialog(false));
          if(onCancellation) onCancellation();
        }}
        rounded
        backgroundClose
      >
        <p className="text-2xl mb-4 text-primary">{message ?? "Please confirm you want to proceed?"}</p>
        {extraContent ?? null}
        <div className="flex justify-between items-center gap-6">
          <Button
            title={no ?? "No, Cancel!"}
            type="accent"
            onClick={() => {
              dispatch(setShowConfirmationDialog(false));
              if(onCancellation) onCancellation();
            }}
          />
          <Button
            title={yes ?? "Yes, Proceed!"}
            type="primary"
            onClick={() => {
              dispatch(setShowConfirmationDialog(false));
              onConfirmation();
            }}
          />
        </div>
      </Modal>
    </>
  );
}
