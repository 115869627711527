import { createSlice } from "@reduxjs/toolkit";
import { formatErrors, handleAPI } from "@redux/services/APIHandler";
import { dropdownListFormat } from "@utils/index";

export type globalState = {
  loading: boolean;
  postCoderLoading: boolean;
  message: string | null;
  errors: string[];
  corridors: any;
  packages: any;
  paymentOptions: any;
  currencies: any;
  id_types: any;
  send_money_options: any;
  banksList: any;
  branchList: any;
  branches: any;
  provinces: any;
  ukAddresses: any;
  listCountries: any;
};

const initialState: globalState = {
  loading: false,
  postCoderLoading: false,
  message: null,
  errors: [],
  corridors: null,
  packages: null,
  paymentOptions: null,
  currencies: null,
  id_types: null,
  send_money_options: null,
  banksList: null,
  branchList: null,
  branches: null,
  provinces: null,
  ukAddresses: null,
  listCountries: null,
};

// API Calls
export const getCorridors = handleAPI("ui/getCorridors", "get", `corridors/list/:country`);
export const getPackages = handleAPI("ui/getPackages", "get", `remitter/packages/:country`);
export const getPaymentMethods = handleAPI("ui/getPaymentMethods", "get", "payment/methods/list");
export const getCountries = handleAPI("ui/getCountries", "get", "countries");
export const getSendMoneyOptions = handleAPI("ui/getSendMoneyOptions", "get", "send/money/opt");
export const getProvinces = handleAPI("ui/getProvinces", "get", "provinces");
export const getIDTypes = handleAPI("ui/getIDTypes", "get", "id/types");
export const getBankList = handleAPI("global/banks", "get", "global/banks");
export const searchUKAddress = handleAPI("ui/postCoder", "get", "postcoder/:postcode");
export const getBranchTeller = handleAPI("global/branch-teller/", "get", `/global/branch-teller/:branchId`);
export const getBranches = handleAPI("global/branches", "get", `/global/branches`);


export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    clearUIOptions: (state) => {
      state = initialState;
      state.paymentOptions = null;
    },
    clearUKAddress: (state) => {
      state.ukAddresses = null;
    },
    clearBranches: (state) => {
      state.branches = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCorridors.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.corridors = null;
      state.packages = null;
    });
    builder.addCase(getCorridors.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.corridors = payload?.response ? dropdownListFormat(payload.response, "code", "corridor") : null;
    });
    builder.addCase(getCorridors.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    // =================================================================================================
    builder.addCase(getPackages.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.packages = null;
    });
    builder.addCase(getPackages.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.packages = payload?.response ?? null;
    });
    builder.addCase(getPackages.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    // =================================================================================================
    builder.addCase(getPaymentMethods.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.paymentOptions = null;
    });
    builder.addCase(getPaymentMethods.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.paymentOptions = payload?.response ?? null;
    });
    builder.addCase(getPaymentMethods.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    // =================================================================================================
    // =================================================================================================
    builder.addCase(getSendMoneyOptions.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.send_money_options = null;
    });
    builder.addCase(getSendMoneyOptions.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.send_money_options = payload?.response ?? null;
    });
    builder.addCase(getSendMoneyOptions.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    // =================================================================================================
    builder.addCase(getProvinces.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.provinces = null;
    });
    builder.addCase(getProvinces.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.provinces = payload?.response ?? null;
    });
    builder.addCase(getProvinces.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    // =================================================================================================
    builder.addCase(getIDTypes.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.id_types = null;
    });
    builder.addCase(getIDTypes.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.id_types = payload?.response ?? null;
    });
    builder.addCase(getIDTypes.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    // =================================================================================================
    builder.addCase(searchUKAddress.pending, (state) => {
      state.postCoderLoading = true;
      state.errors = [];
      state.message = null;
      state.ukAddresses = null;
    });
    builder.addCase(searchUKAddress.fulfilled, (state, { payload }: any) => {
      state.postCoderLoading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.ukAddresses = payload?.response ?? null;
    });
    builder.addCase(searchUKAddress.rejected, (state, { payload }: any) => {
      state.postCoderLoading = false;
      state.errors = formatErrors(payload);
    });
    // =================================================================================================
    builder.addCase(getCountries.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.listCountries = null;
    });
    builder.addCase(getCountries.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.listCountries = payload?.response ?? null;
    });
    builder.addCase(getCountries.rejected, (state, { payload }: any) => {
      state.postCoderLoading = false;
      state.errors = formatErrors(payload);
    });
    // =================================================================================================
    builder.addCase(getBankList.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.banksList = null;
    });
    builder.addCase(getBankList.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.banksList = payload?.response ?? null;
    });
    builder.addCase(getBankList.rejected, (state, { payload }: any) => {
      state.postCoderLoading = false;
      state.errors = formatErrors(payload);
    });
    // =================================================================================================
    builder.addCase(getBranchTeller.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.branchList = null;
    });
    builder.addCase(getBranchTeller.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.branchList = payload?.response ?? null;
    });
    builder.addCase(getBranchTeller.rejected, (state, { payload }: any) => {
      state.postCoderLoading = false;
      state.errors = formatErrors(payload);
    });
    // =================================================================================================
    builder.addCase(getBranches.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.branches = null;
    });
    builder.addCase(getBranches.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.branches = payload?.response ?? null;
    });
    builder.addCase(getBranches.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    // =================================================================================================
  },
});

//Export actions
export const { clearUIOptions, clearUKAddress, clearBranches } = uiSlice.actions;

export default uiSlice.reducer;
