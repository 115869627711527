import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../services/API";
import { resetTokens, restartAuth, refreshToken } from "./authSlice";
import { endpoint } from "../endpoint";
import { formatErrors, handleAPI } from "@redux/services/APIHandler";

export type beneficiaryState = {
  loading: boolean;
  message: string | null;
  errors: string[];
  beneficiaries: any | null;
  details: any | null;
  transactions: any | null;
  createdBeneficiary: any | null;
  beneficiaryBanks: any | null;
  transactionReports: any | null;
};

const initialState: beneficiaryState = {
  loading: false,
  message: null,
  errors: [],
  beneficiaries: null,
  details: null,
  transactions: null,
  createdBeneficiary: null,
  beneficiaryBanks: null,
  transactionReports: null,
};

export const searchBeneficiary = handleAPI("beneficiaries/search", "post", `beneficiary/search`);
export const beneficiaryDetails = handleAPI("beneficiaries/details", "get", `beneficiary/:id`);
export const getBeneficiaryBanks = handleAPI("beneficiary/bank", "get", `beneficiary/bank/:id`);
export const createBeneficiary = handleAPI("beneficiaries/create", "post", `beneficiary/create-or-update`);
export const getBeneficiaryReports = handleAPI(
  "beneficiary/transactions/reports",
  "post",
  `/reports/beneficiary/transactions/:id`
);

export const beneficiarySlice = createSlice({
  name: "beneficiary",
  initialState,
  reducers: {
    clearBenefErrors: (state) => {
      state.errors = [];
    },
    benefBankingDetails: (state) => {
      state.beneficiaryBanks = null;
    },
    clearBeneficiary: (state) => {
      state.createdBeneficiary = null;
    },
    clearDetails: (state) => {
      state.details = null;
    },
    clearBeneficiaryReports: (state) => {
      state.transactionReports = null;
    },
    clearSearch: (state) => {
      state.loading = false;
      state.message = null;
      state.errors = [];
      state.beneficiaries = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchBeneficiary.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.beneficiaries = null;
    });
    builder.addCase(searchBeneficiary.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.beneficiaries = payload?.response ?? null;
    });
    builder.addCase(searchBeneficiary.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.beneficiaries = null;
      state.errors = formatErrors(payload);
    });

    builder.addCase(beneficiaryDetails.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.details = null;
      state.transactions = null;
    });
    builder.addCase(beneficiaryDetails.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.details = payload?.response?.details ?? null;
      state.transactions = payload?.response?.transactions ?? null;
    });
    builder.addCase(beneficiaryDetails.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    // ====================================================================================================================================
    builder.addCase(createBeneficiary.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.createdBeneficiary = null;
    });
    builder.addCase(createBeneficiary.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.createdBeneficiary = payload?.response ?? null;
    });
    builder.addCase(createBeneficiary.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    // ==============================================
    builder.addCase(getBeneficiaryBanks.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.beneficiaryBanks = null;
    });
    builder.addCase(getBeneficiaryBanks.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.beneficiaryBanks = payload?.response?.details ?? null;
    });
    builder.addCase(getBeneficiaryBanks.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    // ==============================================
    builder.addCase(getBeneficiaryReports.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.transactionReports = null;
    });
    builder.addCase(getBeneficiaryReports.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.transactionReports = payload?.response?.data ?? null;
    });
    builder.addCase(getBeneficiaryReports.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
  },
});

//Export actions
export const {
  clearSearch,
  clearBeneficiary,
  clearDetails,
  clearBenefErrors,
  benefBankingDetails,
  clearBeneficiaryReports,
} = beneficiarySlice.actions;

export default beneficiarySlice.reducer;
