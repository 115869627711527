import { createSlice } from "@reduxjs/toolkit";
import { formatErrors, handleAPI } from "@redux/services/APIHandler";

export type WalletState = {
  loading: boolean;
  message: string | null;
  errors: string[];
  details: any | null;
  charges: any | null;
  add: any | any;
  search: any | null;
  checkTransaction: any | null;
  confirm: any | null;
  cancel: any | null;
  duplicate: any | null;
};

const initialState: WalletState = {
  loading: false,
  message: null,
  errors: [],
  details: null,
  charges: null,
  add: null,
  search: null,
  checkTransaction: null,
  confirm: null,
  cancel: null,
  duplicate: null,
};

export const createDeposit = handleAPI("wallet/deposit/create", "post", "wallet/deposit");
export const searchWallet = handleAPI("wallet/search", "post", "wallet/search/member/:mobile");
export const makeWithdrawal = handleAPI("wallet/withdraw", "post", "wallet/make/withdrawal");
export const walletDuplicateCheck = handleAPI("wallet/deposit/checkDuplicateDeposit", "post", "wallet/deposit/check/duplicate");
export const confirmWithdrawal = handleAPI("wallet/confirm/withdrawal", "post", "wallet/confirm/withdrawal");


// transaction/details/RC062845003280

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errors = [];
    },
    clearDeposit: (state) => {
      state.loading = false;
      state.errors = [];
      state.add = null;
    },
    clearWithdrawal: (state) => {
      state.loading = false;
      state.errors = [];
      state.checkTransaction = null;
      state.duplicate=null
    },
    clearConfirm: (state) => {
      state.loading = false;
      state.errors = [];
      state.confirm = null;
    },
    clearDuplicate: (state) => {
      state.loading = false;
      state.errors = [];
      state.duplicate = null;
    },
    clearSearch: (state) => {
      state.loading = false;
      state.message = null;
      state.errors = [];
      state.search = null;
      state.duplicate=null;
    },
    clearWallet: (state) => {
      state.loading = false;
      // state.message = null;
      state.errors = [];
      state.add = null;
      state.search = null;
      state.duplicate=null
      state.checkTransaction = null;
      state.confirm = null;
      state.duplicate=null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createDeposit.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
    });
    builder.addCase(createDeposit.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.data;
      state.add = payload;
    });
    builder.addCase(createDeposit.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    //=============================================================================================
    builder.addCase(searchWallet.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.search = null;
    });
    builder.addCase(searchWallet.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message;
      state.search = payload ?? null;
      
    });
    builder.addCase(searchWallet.rejected, (state, { payload }: any) => {
      state.loading = false;
      console.log(formatErrors(payload));
      
      state.errors = formatErrors(payload);
    });

    // =============================================================================
    builder.addCase(makeWithdrawal.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
    });
    builder.addCase(makeWithdrawal.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message;
      state.checkTransaction = payload?? null;
    });
    builder.addCase(makeWithdrawal.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
 
    });
    // =============================================================================
    builder.addCase(confirmWithdrawal.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
    });
    builder.addCase(confirmWithdrawal.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message;
      state.confirm = payload ?? null;
    });
    builder.addCase(confirmWithdrawal.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors =formatErrors(payload);
    });

        // =============================================================================
        builder.addCase(walletDuplicateCheck.pending, (state) => {
          state.loading = true;
          state.errors = [];
          state.message = null;
        });
        builder.addCase(walletDuplicateCheck.fulfilled, (state, { payload }: any) => {
          state.loading = false;
          state.errors = [];
          state.message = payload?.message;
          state.duplicate = payload?.data ?? null;
        });
        builder.addCase(walletDuplicateCheck.rejected, (state, { payload }: any) => {
          state.loading = false;
          state.errors =formatErrors(payload);
        });
  },
});

//Export actions
export const {clearDeposit, clearSearch,clearWithdrawal, clearConfirm, clearWallet, clearDuplicate} = walletSlice.actions;

export default walletSlice.reducer;
