export const endpoint = (): any => {
  if (process.env.REACT_APP_AFX_ENVIRONMENT === "live") return process.env.REACT_APP_LIVE_API_ENDPOINT;
  if (process.env.REACT_APP_AFX_ENVIRONMENT === "test") return process.env.REACT_APP_TEST_API_ENDPOINT;
  if (process.env.REACT_APP_AFX_ENVIRONMENT === "staging") return process.env.REACT_APP_STAGING_API_ENDPOINT;
};

export const remitone = (): string => {
  let result: string =
    (process.env.REACT_APP_AFX_ENVIRONMENT === "live"
      ? process.env.REACT_APP_LIVE_REMITONE_LINK
      : process.env.REACT_APP_AFX_ENVIRONMENT === "staging"
      ? process.env.REACT_APP_STAGING_REMITONE_LINK
      : process.env.REACT_APP_TEST_REMITONE_LINK) || "";

  return result;
};

export const cms = (): string => {
  let result: string =
    (process.env.REACT_APP_AFX_ENVIRONMENT === "live"
      ? process.env.REACT_APP_LIVE_CMS_LINK
      : process.env.REACT_APP_TEST_CMS_LINK) || "";

  return result;
};

export const afxapi = (): string => {
  let result: string =
  (process.env.REACT_APP_AFX_ENVIRONMENT === "live"
    ? process.env.REACT_APP_LIVE_AFX_API_LINK
    : process.env.REACT_APP_AFX_ENVIRONMENT === "staging"
    ? process.env.REACT_APP_STAGING_AFX_API_LINK
    : process.env.REACT_APP_TEST_AFX_API_LINK) || "";

  return result;
};
export const environment = process.env.REACT_APP_AFX_ENVIRONMENT;
export const app_version = process.env.REACT_APP_VERSION;

export enum envType {
  live = "live",
  test = "test",
  staging = "staging"
}
