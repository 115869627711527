import React,{useEffect} from 'react'
import { restartAuth } from "@redux/slices/authSlice";
import { useAppDispatch } from "@redux/hooks";


const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
];

export default function AutoLogout({ children }:any) {

    const dispatch = useAppDispatch();

    let timer: any;

// this function sets the timer that logs out the user after 10 secs
const handleLogoutTimer = () => {
  timer = setTimeout(() => {
    // clears any pending timer.
    resetTimer();
    // Listener clean up. Removes the existing event listener from the window
    Object.values(events).forEach((item) => {
      window.removeEventListener(item, resetTimer);
    });
    // logs out user
    // logoutAction();
    dispatch(restartAuth("Session Expired! Please sign in again."));
  }, 600000); // 60000 ms = 1 minute. You can change the time.
};

// this resets the timer if it exists.
const resetTimer = () => {
  if (timer) clearTimeout(timer);
};

// when component mounts, it adds an event listeners to the window
// each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
// However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
useEffect(() => {
  Object.values(events).forEach((item) => {
    window.addEventListener(item, () => {
      resetTimer();
      handleLogoutTimer();
    });
  });
}, []);

// logs out user by clearing out auth token in localStorage and redirecting url to /login page.
// const logoutAction = () => {
//   localStorage.clear();
//   window.location.pathname = "/login";
// };

    return children;
}
