import "./OverlayLoader.css";

interface IProps {
  message: string;
  loading: boolean;
}

export default function OverlayLoader(props: IProps) {
  const { message, loading } = props;
  return loading ? (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-primary flex flex-col items-center justify-center">
      <div className="loader ease-linear rounded-full border-4 border-t-4 border-secondary h-12 w-12 mb-4"></div>
      <h2 className="text-center text-white text-xl font-semibold">{message}</h2>
      <p className="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p>
    </div>
  ) : null;
}
