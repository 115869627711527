import React from "react";
import { IoChevronBackSharp, IoChevronForwardSharp, IoMenu } from "react-icons/io5";
import { useAppDispatch } from "../../redux/hooks";
import { toggleMobileMenu } from "../../redux/slices/globalSlice";
import { isMobile, isTablet } from "../../utils/device";

import { useNavigate } from "react-router-dom";
import { Button } from "@components/form-elements";

interface IProps {
  parent: string;
  title?: string;
  link?: string;
  currentPage: string;
}

export default function Breadcrumbs({ parent, title, link, currentPage }: IProps) {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  return (
    <nav
      className="flex justify-between items-center bg-gray-50 text-gray-700 dark:text-gray-300 border border-gray-200 py-3 px-5 rounded-lg dark:bg-gray-800 dark:border-gray-700 mb-4"
      aria-label="Breadcrumbs"
    >
      <div className="flex gap-4 items-center">
      {/* <button clas onClick={() => navigate(-1)}>Back</button> */}
      {/* <Button title="Back" type="primary" onClick={() => navigate(-1)} className="p-2" /> */}
      <span className="flex gap-2 bg-primary py-1 px-2 rounded-md justify-center items-center cursor-pointer" onClick={() => navigate(-1)}>
      <IoChevronBackSharp className="text-gray-300 text-sm font-bold" />
      <span className="text-gray-300 text-sm">Back</span>
      </span>
      <h2 className="sm:text-lg md:text-2xl font-bold">{title ?? parent}</h2>
      </div>
      <ol className="hidden lg:inline-flex items-center space-x-1 md:space-x-3 ">
        <li className="inline-flex items-center sm:text-xs md:text-base">Home</li>
        <li className="sm:text-xs md:text-base">
          <div className="flex items-center">
            <svg
              className="w-6 h-6 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>

            {parent}
          </div>
        </li>
        <li aria-current="page">
          <div className="flex items-center">
            <svg
              className="w-6 h-6 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="text-gray-400 ml-1 md:ml-2 sm:text-xs md:text-base font-medium dark:text-gray-500">
              {currentPage}
            </span>
          </div>
        </li>
      </ol>
      <IoMenu
        onClick={() => dispatch(toggleMobileMenu())}
        className="text-2xl text-primary cursor-pointer visible lg:hidden"
      />
    </nav>
  );
}
