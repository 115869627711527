import React, { useState } from "react";
import "./App.css";
import Routes from "./Routes";
import { HelmetProvider } from "react-helmet-async";
import CacheBuster from 'react-cache-buster';
import OverlayLoader from "@components/layout/loaders/OverlayLoader";
import { app_version } from "@redux/endpoint";

function App() {
  return (
    <CacheBuster
      currentVersion={app_version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<OverlayLoader message="Checking For New Updates" loading={true} />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <HelmetProvider>
        <Routes />
      </HelmetProvider>
    </CacheBuster>
  );
}

export default App;

