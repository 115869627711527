export const formatObjectKey = (obj: any) => {
  obj = obj.replaceAll("_", " ");
  const arr = obj.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(" ");
};

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const openInNewTab = (url: string) => {
  ///@ts-ignore
  window.open(url, "_blank", "noopener,noreferrer").focus();
};

export const _find = (collection: any, key: any, value: any) => {
  for (const o of collection) {
    for (const [k, v] of Object.entries(o)) {
      if (k === key && v === value) {
        return o;
      }
      if (Array.isArray(v)) {
        const _o: any = _find(v, key, value);
        if (_o) {
          return _o;
        }
      }
    }
  }
};

export const isset = (val: any) => {
  return typeof val !== "undefined" && val !== null;
};

export { DisplayFormikState } from "./DisplayFormikState";

export const dropdownListFormat = (data: any, valueKey: any, labelKey: any) => {
  let newSet: any = [];
  data.forEach((e: any) => {
    newSet.push({ value: e[valueKey], label: e[labelKey] });
  });
  return newSet;
};

export function numberSeparator(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function isNumeric(n:any) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const currencySymbol = (e: string) => {
  return e === "USD" ? "$" : e === "ZAR" ? "R" : e === "GBP" ? "£" : e;
};

export const toTitleCase = (input: string) => {
  const words = input.split(" ");
  const titleWords = []; // The array of title words
  for (const word of words) {
    const firstLetter = word.slice(0, 1).toLocaleUpperCase(); // Capitalize the first letter
    const rest = word.slice(1); // Not modify this part
    titleWords.push(`${firstLetter}${rest}`);
  }
  const result = titleWords.join(" ");
  return result;
};

export function dashify(inp: string, sep: string) {
  inp = inp.replaceAll(sep, "");
  const rzult: string[] = [];
  for (let x = 0; x < inp.length; x++) {
    x !== 0 ? rzult.push(sep, inp[x]) : rzult.push(inp[x]);
  }
  return rzult.join("");
}

export const dd = (input: any) => JSON.stringify(input, null, 2);


export const oneMonthAgo = () => {
 // Get a date object for the current time
 let d = new Date();
 // Set it to one month ago
 d.setMonth(d.getMonth() - 1);
 
 return d;
}

export async function copyTextToClipboard(text:string) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}


export function scrollToTop(){
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}

export function EighteenYearsAgo(){
  const currentDate = new Date(); // current date
  const currentYear = currentDate.getFullYear(); // current year
  const year18Ago = currentYear - 18; // 18 years ago
  return new Date(year18Ago, currentDate.getMonth(), currentDate.getDate()); // date 18 years ago

}

/*
Converts a base64 string to a Blob file.
@param file - The base64 string to convert.
@returns A Blob file.
@throws {Error} If the provided string is not a valid base64 string. 
**/ 
// export const convertBase64ToFile2 = (file: string): Blob => {
//   const match = /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,(.*)$/.exec(file);
//   if (!match) {
//     throw new Error('Invalid base64 string');
//   }
//   const mimeType = match[1];
//   const byteString = atob(match[2]);
//   const ab = new ArrayBuffer(byteString.length);
//   const ia = new Uint8Array(ab);
//   for (let i = 0; i < byteString.length; i += 1) {
//     ia[i] = byteString.charCodeAt(i);
//   }
//   const newBlob = new Blob([ab], {
//     type: mimeType,
//   });
//   return newBlob;
// };

export const convertBase64ToFile = (file: string, filename: string): { blob: Blob, path: string } => {
  const match = /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,(.*)$/.exec(file);
  if (!match) {
    throw new Error('Invalid base64 string');
  }
  const mimeType = match[1];
  const byteString = atob(match[2]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  const newBlob = new Blob([ab], {
    type: mimeType,
  });

  // Generate a random filename using the current date and time
  const timestamp = Date.now();
  const extension = mimeType.split('/')[1];
  const path = `${timestamp}-${filename}.${extension}`;

  return { blob: newBlob, path };
};

// export const convertBase64ToFile = (base64File: string): File => {
//   const match = /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,(.*)$/.exec(base64File);
//   if (!match) {
//     throw new Error('Invalid base64 string');
//   }
//   const mimeType = match[1];
//   const i = base64File.indexOf('base64,');
//   const buffer = Uint8Array.from(atob(base64File.slice(i + 7)), c => c.charCodeAt(0));
//   const extension = mimeType.split('/')[1];
//   const randomName = `${Date.now()}_${Math.floor(Math.random() * 100000)}.${extension}`;
//   const file = new File([buffer], randomName, { type: mimeType });
//   return file;
// };
